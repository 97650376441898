import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailRegistrationStatusComponent from "../../components/detail/DetailRegistrationStatusComponent";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";
import IconError from "../../assets/icon-error";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import DetailContentWithEditComponent from "../../components/detail/DetailContentWithEditComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import {
  registrationList,
  registrationVerification,
  ppdsStatusApproval,
} from "../../actions/registrasi_sp2/registrasi_action";
import {
  getRegistrationListPending,
  getRegistrationList,
  getRegistrationListError,
  getRegistrationVerification,
  getRegistrationVerificationPending,
  getRegistrationVerificationError,
  getppdsStatusApproval,
  getppdsStatusApprovalPending,
  getppdsStatusApprovalError,
} from "../../reducers/registrasi_sp2/registrasi_reducer";
import { connect } from "react-redux";
import ModalRegistrationVerification from "./Modal_registrasi";
import ModalRegistrationRejection from "./Modal_registrasi_reject";
import ModalPPDSConfirmation from "./Modal_konfirmasi_ppds";
import ModalPPDSRejection from "./Modal_reject_ppds";
import ModalBuktiPembayaran from "./Modal_detail_bukti_pembayaran";
import ModalEditProdiTujuan from "./Modal_edit_prodi_tujuan";
import ModalEditPeminatan from "./Modal_edit_peminatan";
import ModalEditCatatan from "./Modal_edit_catatan";
import ModalEditNoKolegium from "./Modal_edit_no_kolegium";
import ModalBuktiPembayaranPDf from "../../components/global-components/Modal_document_pdf";
import EditComponent from "../../components/global-components/EditComponent";

class DetailRegistrasiInformasiPPDS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVerificationShow: false,
      modalRejectionShow: false,
      modalApprovePPDSShow: false,
      modalRejectPPDSShow: false,
      modalBuktiPembayaranShow: false,
      modalEditProdiTujuanShow: false,
      modalEditPeminatanShow: false,
      modalEditCatatan: false,
      modalBuktiPembayaranPDFShow: false,
      imgPayment: null,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  informasiUmum(id) {
    this.props.history.push(`/registrasi-sp2/detail/informasi-umum/${id}`);
  }

  pendidikanAsal(id) {
    this.props.history.push(`/registrasi-sp2/detail/pendidikan-asal/${id}`);
  }

  informasiPPDS(id) {
    this.props.history.push(`/registrasi-sp2/detail/informasi-ppds/${id}`);
  }

  componentDidMount() {
    let param_registrationList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_registrationList.id = id;
    this.props.registrationList(param_registrationList);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }
  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgPayment: imgUrl });
    if (imgUrl.split(".")[1] === "pdf") {
      this.setState({ modalBuktiPembayaranPDFShow: true });
    } else {
      this.setState({ modalBuktiPembayaranShow: true });
    }
  }
  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const {
      modalVerificationShow,
      modalRejectionShow,
      modalApprovePPDSShow,
      modalRejectPPDSShow,
      modalBuktiPembayaranShow,
      modalBuktiPembayaranPDFShow,
      modalEditProdiTujuanShow,
      modalEditPeminatanShow,
      modalEditCatatanShow,
      modalEditNoKolegiumShow,
    } = this.state;
    const { registrationList_data } = this.props;
    let data = [];
    let prodi_tujuan = "";
    // let img_payment_link = "";
    let prodi_tujuan_label_modal = "";
    let prodi_tujuan_value_modal = "";
    let status_ppds_label = "";
    let status_registration_label = "";
    let prodi_tujuan_bulan = "";
    let prodi_tujuan_tahun = "";
    let kolegium_no = "";
    let interest = "";
    // eslint-disable-next-line no-unused-vars

    let email,
      name,
      ip_s1,
      ip_profesi,
      ip_cumulation,
      university_before,
      graduated,
      birthday,
      branch,
      working_hospital_1,
      working_hospital_2,
      working_hospital_3,
      place_of_birth,
      address,
      handphone,
      npa_idi,
      catatan,
      status,
      messageTitle,
      message,
      reject_reason,
      imgUrl = "";
    if (registrationList_data) {
      data = registrationList_data.data[0];
      prodi_tujuan =
        data.ppds_destination_university_data[0].university_data.abbreviation +
        "/" +
        data.ppds_destination_university_data[0].period_selection_month +
        "/" +
        data.ppds_destination_university_data[0].period_selection_year;
      // img_payment_link = "https://dev.api.paboi.bigio.id/v1/image/show/ppds_payment/" + data.img_payment;
      prodi_tujuan_label_modal =
        data.ppds_destination_university_data[0].university_data.abbreviation +
        " - Fakultas Kedokteran " +
        data.ppds_destination_university_data[0].university_data.name;
      prodi_tujuan_value_modal =
        data.ppds_destination_university_data[0].university_id;
      status_ppds_label =
        data.status_ppds === 0
          ? "New"
          : data.status_ppds === 1
          ? "Disetujui"
          : "Gagal";
      status_registration_label =
        data.status_registration === 0
          ? "New"
          : data.status_registration === 1
          ? "Disetujui"
          : "Gagal";
      prodi_tujuan_bulan =
        data.ppds_destination_university_data[0].period_selection_month;
      prodi_tujuan_tahun =
        data.ppds_destination_university_data[0].period_selection_year;
      interest =
        data.ppds_destination_university_data[0].interest == null ||
        data.ppds_destination_university_data[0].interest == ""
          ? "-"
          : data.ppds_destination_university_data[0].interest;
      email = data.email;
      name = data.name;
      university_before = data.university_before;
      graduated = data.graduated;
      birthday = data.birthday;
      branch = data.user_data.branch_data.name;
      working_hospital_1 = data.working_hospital_1;
      working_hospital_2 = data.working_hospital_2;
      working_hospital_3 = data.working_hospital_3;
      place_of_birth = data.place_of_birth;
      address = data.address;
      handphone = data.handphone;
      npa_idi = data.npa_idi;
      ip_s1 = data.ip_s1;
      ip_profesi = data.ip_profesi;
      ip_cumulation = data.ip_cumulation;
      kolegium_no = data.kolegium_no;
      catatan = data.notes;
      status = data.status_registration;
      messageTitle =
        status === 0 ? "Baru" : status === 1 ? "Terverifikasi" : "Ditolak";
      message =
        status === 0
          ? "Verifikasi berkas pembayaran"
          : status === 1
          ? "Konfirmasi hasil tes peserta"
          : "Berkas bukti pendaftaran tidak Sesuai. Silahkan upload ulang berkas pembayaran.";
      reject_reason =
        data.ppds_reject_data.length > 0 ? data.ppds_reject_data[0].notes : "";
      imgUrl =
        data.ppds_reject_data.length > 0
          ? data.ppds_reject_data[0].attachment_list[0].attachment_name
          : "";
    }
    return (
      <ContentContainer>
        <Row>
          <ModalRegistrationVerification
            show={modalVerificationShow}
            onHide={() => this.setState({ modalVerificationShow: false })}
            id={id}
            kolegium_no={kolegium_no === null ? null : kolegium_no}
            notes={catatan === null ? null : catatan}
          />
          <ModalRegistrationRejection
            show={modalRejectionShow}
            onHide={() => this.setState({ modalRejectionShow: false })}
            id={id}
          />
          <ModalPPDSConfirmation
            show={modalApprovePPDSShow}
            onHide={() => this.setState({ modalApprovePPDSShow: false })}
            id={id}
            optionLabel={prodi_tujuan_label_modal}
            graduated={graduated}
            optionValue={prodi_tujuan_value_modal}
            university_before={university_before}
          />
          <ModalPPDSRejection
            show={modalRejectPPDSShow}
            onHide={() => this.setState({ modalRejectPPDSShow: false })}
            id={id}
          />
          <ModalBuktiPembayaran
            show={modalBuktiPembayaranShow}
            onHide={() => this.setState({ modalBuktiPembayaranShow: false })}
            imgUrl={data.img_payment}
          />
          <ModalBuktiPembayaranPDf
            show={modalBuktiPembayaranPDFShow}
            onHide={() => this.setState({ modalBuktiPembayaranPDFShow: false })}
            modal_title={"Pembayaran"}
            folder="ppds_payment"
            imgUrl={this.state.imgPayment}
          />
          <ModalEditProdiTujuan
            show={modalEditProdiTujuanShow}
            onHide={() => this.setState({ modalEditProdiTujuanShow: false })}
            id={id}
            prodi={prodi_tujuan_value_modal}
            bulan={prodi_tujuan_bulan}
            tahun={prodi_tujuan_tahun}
            interest={interest}
            branch={branch}
            email={email}
            name={name}
            university_before={university_before}
            graduated={graduated}
            birthday={birthday}
            place_of_birth={place_of_birth}
            address={address}
            handphone={handphone}
            ip_cumulation={ip_cumulation}
            ip_s1={ip_s1}
            ip_profesi={ip_profesi}
            npa_idi={npa_idi}
            notes={catatan}
            kolegium_no={kolegium_no}
            working_hospital_1={working_hospital_1}
            working_hospital_2={working_hospital_2}
            working_hospital_3={working_hospital_3}
          />
          <ModalEditPeminatan
            show={modalEditPeminatanShow}
            onHide={() => this.setState({ modalEditPeminatanShow: false })}
            id={id}
            prodi={prodi_tujuan_value_modal}
            bulan={prodi_tujuan_bulan}
            tahun={prodi_tujuan_tahun}
            interest={interest}
            email={email}
            name={name}
            university_before={university_before}
            graduated={graduated}
            birthday={birthday}
            place_of_birth={place_of_birth}
            address={address}
            handphone={handphone}
            ip_cumulation={ip_cumulation}
            ip_s1={ip_s1}
            ip_profesi={ip_profesi}
            npa_idi={npa_idi}
            notes={catatan}
            kolegium_no={kolegium_no}
            working_hospital_1={working_hospital_1}
            working_hospital_2={working_hospital_2}
            working_hospital_3={working_hospital_3}
          />
          <ModalEditCatatan
            show={modalEditCatatanShow}
            onHide={() => this.setState({ modalEditCatatanShow: false })}
            id={id}
            prodi={prodi_tujuan_value_modal}
            bulan={prodi_tujuan_bulan}
            tahun={prodi_tujuan_tahun}
            interest={interest}
            branch={branch}
            email={email}
            name={name}
            university_before={university_before}
            graduated={graduated}
            birthday={birthday}
            place_of_birth={place_of_birth}
            address={address}
            handphone={handphone}
            ip_cumulation={ip_cumulation}
            ip_s1={ip_s1}
            ip_profesi={ip_profesi}
            npa_idi={npa_idi}
            notes={catatan}
            kolegium_no={kolegium_no}
            working_hospital_1={working_hospital_1}
            working_hospital_2={working_hospital_2}
            working_hospital_3={working_hospital_3}
          />
          <ModalEditNoKolegium
            show={modalEditNoKolegiumShow}
            onHide={() => this.setState({ modalEditNoKolegiumShow: false })}
            id={id}
            prodi={prodi_tujuan_value_modal}
            bulan={prodi_tujuan_bulan}
            tahun={prodi_tujuan_tahun}
            interest={interest}
            branch={branch}
            email={email}
            name={name}
            university_before={university_before}
            graduated={graduated}
            birthday={birthday}
            place_of_birth={place_of_birth}
            address={address}
            handphone={handphone}
            npa_idi={npa_idi}
            ip_cumulation={ip_cumulation}
            ip_s1={ip_s1}
            ip_profesi={ip_profesi}
            notes={catatan}
            kolegium_no={kolegium_no}
            working_hospital_1={working_hospital_1}
            working_hospital_2={working_hospital_2}
            working_hospital_3={working_hospital_3}
          />
          <Row className={css(styles.leftDetailContainer)}>
            <Column>
              <DetailNameComponent name={data.name} />
              <DetailRegistrationStatusComponent
                icon={IconError}
                status={data.status_registration}
                messageTitle={messageTitle}
                message={message}
                imgUrl={imgUrl}
                reason={reject_reason}
              />
              <DetailMenuComponent
                menuName="Informasi Umum"
                onClick={() => this.informasiUmum(id)}
              />
              <DetailMenuComponent
                menuName="Pendidikan Asal"
                onClick={() => this.pendidikanAsal(id)}
              />
              <DetailMenuComponent menuName="Informasi PPDS" isActive={true} />
              {data.status_registration === 0 && (
                <Row>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon />}
                    className={css(styles.verificationButton)}
                    onClick={() =>
                      this.setState({ modalVerificationShow: true })
                    }
                  >
                    Verifikasi
                  </Button>
                  <Button
                    variant="contained"
                    color=""
                    startIcon={<ClearIcon />}
                    className={css(styles.rejectedButton)}
                    onClick={() => this.setState({ modalRejectionShow: true })}
                  >
                    Tolak
                  </Button>
                </Row>
              )}
            </Column>
          </Row>
          <Row className={css(styles.rightDetailContainer)}>
            <Column className={css(styles.rightDetailColumn)}>
              <DetailTitleComponent title="Informasi PPDS" />
              <DetailContentWithEditComponent
                label="Prodi Tujuan"
                value={prodi_tujuan}
              >
                <EditComponent
                  onClick={() =>
                    this.setState({ modalEditProdiTujuanShow: true })
                  }
                />
              </DetailContentWithEditComponent>
              <DetailContentWithEditComponent
                label="Peminatan"
                value={interest}
              >
                <EditComponent
                  onClick={() =>
                    this.setState({ modalEditPeminatanShow: true })
                  }
                />
              </DetailContentWithEditComponent>
              <DetailContentComponent
                label="Status Registrasi"
                color="blue"
                value={status_registration_label}
              />
              {data.status_ppds === 0 && status !== 0 && status !== 2 ? (
                <DetailContentComponent
                  label="Status PPDS"
                  value={
                    <Row>
                      <Button
                        variant="contained"
                        color="primary"
                        className={css(styles.verificationButtonPPDS)}
                        onClick={() =>
                          this.setState({ modalApprovePPDSShow: true })
                        }
                      >
                        Terima
                      </Button>
                      <Button
                        variant="contained"
                        className={css(styles.rejectionButtonPPDS)}
                        onClick={() =>
                          this.setState({ modalRejectPPDSShow: true })
                        }
                      >
                        Gagal
                      </Button>
                    </Row>
                  }
                />
              ) : data.status_ppds === 0 && (status === 0 || status === 2) ? (
                <DetailContentComponent label="Status PPDS" value="-" />
              ) : (
                <DetailContentComponent
                  label="Status PPDS"
                  value={status_ppds_label}
                />
              )}
              {data.img_payment !== null ? (
                <DetailContentComponent
                  label="Berkas foto"
                  value={
                    <DetailContentLihatBerkas
                      onClick={() =>
                        this.handleOpenDocument(
                          data.img_payment,
                          "Berkas foto"
                        )
                      }
                    />
                  }
                />
              ) : (
                <DetailContentComponent label="Berkas Foto" value="-" />
              )}
              <DetailContentWithEditComponent
                label="Nomor Kolegium"
                color="blue"
                value={kolegium_no}
              >
                <EditComponent
                  onClick={() =>
                    this.setState({ modalEditNoKolegiumShow: true })
                  }
                />
              </DetailContentWithEditComponent>
              <DetailContentWithEditComponent label="Catatan" value={catatan}>
                <EditComponent
                  onClick={() => this.setState({ modalEditCatatanShow: true })}
                />
              </DetailContentWithEditComponent>
            </Column>
          </Row>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  leftDetailContainer: {
    width: "30%",
    background: "#F8F9FB 0% 0% no-repeat padding-box",
    borderRight: "2px solid rgba(192,192,192, 0.5)",
    opacity: 1,
    padding: 20,
  },
  rightDetailContainer: {
    padding: 40,
    width: "70%",
  },
  rightDetailColumn: {
    width: "100%",
  },
  verificationButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    borderRadius: 5,
    opacity: 1,
    marginLeft: 10,
    marginRight: 20,
    marginTop: 30,
    padding: 15,
  },
  verificationButtonPPDS: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    marginRight: 10,
    boxShadow: "none",
  },
  rejectedButton: {
    background: "#d6d6d6 0% 0% no-repeat padding-box",
    borderRadius: 5,
    opacity: 1,
    marginTop: 30,
    padding: 15,
  },
  rejectedButtonPPDS: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
});

function mapStateToProps({
  registrationList,
  registrationVerification,
  ppdsStatusApproval,
}) {
  return {
    registrationList_pending: getRegistrationListPending(registrationList),
    registrationList_data: getRegistrationList(registrationList),
    registrationList_error: getRegistrationListError(registrationList),
    registrationVerification_pending: getRegistrationVerificationPending(
      registrationVerification
    ),
    registrationVerification_data: getRegistrationVerification(
      registrationVerification
    ),
    registrationVerification_error: getRegistrationVerificationError(
      registrationVerification
    ),
    ppdsStatusApproval_pending:
      getppdsStatusApprovalPending(ppdsStatusApproval),
    ppdsStatusApproval_data: getppdsStatusApproval(ppdsStatusApproval),
    ppdsStatusApproval_error: getppdsStatusApprovalError(ppdsStatusApproval),
  };
}

export default connect(mapStateToProps, {
  registrationList,
  registrationVerification,
  ppdsStatusApproval,
})(withRouter(DetailRegistrasiInformasiPPDS));
